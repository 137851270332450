import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Pagination } from 'swiper'

// Generic
import Button from '../../shared/Button/Button'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Container from '../../shared/Container/Container'

// Swiper components
import SwiperNav from '../../shared/Swiper/SwiperNav'

SwiperCore.use([EffectFade, Pagination])

const BootcampTestimonialCarousel = (props) => {
  const { items, sectionClassName } = props

  const [swiper, setSwiper] = useState(null);

  const slidesPerView = items.length < 4 ? items.length : 4;
  const slidesPerViewColumns = `sm:grid-cols-1 md:grid-cols-${slidesPerView}`;

  return (
    <section className={classNames(sectionClassName)}>
      <div
        className=''
      >
        <Container>
          <div>
            <Swiper
              className={`grid ${slidesPerViewColumns}`}
              onSwiper={setSwiper}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet swiper-shift',
                bulletActiveClass: 'swiper-pagination-bullet-active',
              }}
              preloadImages={false}
              slidesPerView={slidesPerView}
              spaceBetween={20}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                }
              }}
            >
              {items.map((testimonial, testimonialIndex) => {  
                let testimonialRole = testimonial.testimonial_carousel_role.html;
                testimonialRole.replace('<p>', '').replace('</p>', ''); // take away the p tags

                const getFirstRole = testimonialRole.split('to')[0].trim();
                const getCurrentRole = testimonialRole.split('to')[1].trim();
                
                return (
                  <SwiperSlide
                    key={testimonialIndex}
                    className='bg-white border-whiteSmoke border shadow-md'
                  >
                    <div className=''>
                      <div className='w-full'>
                        <Image
                          fallbackAlt={testimonial.testimonial_carousel_name.text}
                          {...testimonial.testimonial_carousel_avatar}
                        />
                      </div>
                      
                      <div className='flex flex-col items-center text-center py-6 justify-center'>
                        <div className='pill bg-tertiary-dark text-black font-medium px-2 inline-block uppercase text-sm rounded-sm'>{getFirstRole}</div>
                        <div className='font-medium py-2'>to</div>
                        <div className='pill bg-primary text-white font-medium px-2 inline-block uppercase text-sm rounded-sm'>{getCurrentRole}</div>
                      </div>

                      <HtmlContent
                        html={testimonial.testimonial_carousel_body.html}
                        className='text-lg c-prose p-4'
                      />

                      {(testimonial.testimonial_carousel_cta_label.text && testimonial.testimonial_carousel_cta_link.url) && (
                        <Button
                          className='text-white hover:text-white'
                          color='inline'
                          icon
                          {...testimonial.testimonial_carousel_cta_link}
                        >
                          {testimonial.testimonial_carousel_cta_label.text}
                        </Button>
                      )}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </Container>
        <SwiperNav
          swiper={swiper}
          wrapperClassName={classNames('hidden md:block w-full mx-auto z-10 transform -translate-y-8')}
          containerClassName='flex flex-row justify-center mt-20'
          ctaClassName='bg-primary text-white border-2 hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
        />
      </div>
    </section>
  )
}

BootcampTestimonialCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  sectionClassName: PropTypes.string,
  preSwiperInnerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  hideBottomEdge: PropTypes.bool
}

export default BootcampTestimonialCarousel

export const query = graphql`
  fragment BootcampTestimonialCarousel on PrismicTestimonialCarousel {
    id
    data {
      testimonial_carousel_items {
        testimonial_carousel_role {
          html
          text
        }
        testimonial_carousel_name {
          text
        }
        testimonial_carousel_cta_label {
          text
        }
        testimonial_carousel_cta_link {
          ...Link
        }
        testimonial_carousel_body {
          html
        }
        testimonial_carousel_avatar {
          ...Image
        }
      }
    }
  }
`
