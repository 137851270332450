import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Edge from '../../shared/Edge/Edge'
import TwoColGrid from '../../shared/TwoColGrid/TwoColGrid'
import Image from '../../shared/Image/Image'

const InfoGrid = (props) => {
  const {
    edgeOuterClassName,
    edgeInnerClassName,
    companyIcons
  } = props;
  return (
    <section
      className=''
    >
      <div
        className={classNames(`bg-primary`)}
      >
        <Edge
          location='top'
          direction='left'
          outerClassName='text-white'
          innerClassName='text-primary'
        />
        <Container width='thinner' className='py-10 sm:py-20'>
          <p className='text-center text-xl text-white font-medium mb-4'>Students have access to our careers team who have helped graduates get jobs at companies like...</p>
          <div className='sm:grid grid-cols-4 gap-x-6 gap-y-10 space-y-10 sm:space-y-0 bg-white text-black p-6 text-center'>
            {companyIcons.map(({company_icon}, index) => {
              return (
                <Image
                  key={index}
                  fallbackAlt={"Company Icon"}
                  {...company_icon}
                />
              );
            })}
          </div>
        </Container>
      </div>

      <Edge
        location='bottom'
        direction='left'
        outerClassName={edgeOuterClassName ? edgeOuterClassName : 'text-white'}
        innerClassName={edgeInnerClassName ? edgeInnerClassName : 'text-primary'}
      />
    </section>
  )
}

InfoGrid.propTypes = {
  edgeOuterClassName: PropTypes.string,
  edgeInnerClassName: PropTypes.string,
}

InfoGrid.defaultProps = {
  edgeOuterClassName: 'text-white',
  edgeInnerClassName: 'text-primary',
}

export default InfoGrid

export const query = graphql`
  fragment InfoGrid on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyInfoGrid {
          id
          slice_type
          primary {
            display
            info_grid_image {
              ...Image
            }
            info_grid_intro {
              html
            }
            info_grid_title {
              text
              raw
            }
            info_grid_video_embed {
              ...Embed
            }
            info_grid_media_overlap_background
            info_grid_media_overlap_height
            info_grid_hubspot_form_guid
            info_grid_has_edge
            info_grid_remove_bottom_margin
          }
          items {
            info_grid_icon {
              ...Image
            }
            info_grid_header {
              text
              raw
            }
            info_grid_body {
              html
            }
          }
        }
      }
    }
  }
`
