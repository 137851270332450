import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import BootcampView from '../components/views/BootcampView/BootcampView'

// Utils
import getCustomRobotsFromTags from '../utils/getCustomRobotsFromTags'

const BootcampViewTemplate = ({ data, location }) => {
  // The link will be like: "/our-courses/coding-bootcamp-north-west"
  const pathName = location.pathname;
  const queries = location.search;
  let pageLocation = pathName.split('/')[2].split('-').splice(2).join(' ');
  // go to catch all (England) if nothing in url
  if (pageLocation === '') {
    pageLocation = 'all';
  }

  const params = new URLSearchParams(queries);

  const utmContent = params.get('utm_content');

  const locationMap = {
    'north west': {
      title: 'Manchester'
    },
    'north east': {
      title: 'Newcastle',
    },
    'yorkshire': {
      title: 'Leeds',
    },
    'midlands': {
      title: 'Birmingham',
    },
    'south': {
      title: 'England'
    },
    'scotland': {
      title: 'Remote'
    },
    'all': {
      title: 'England',
    }
  }

  const currentLocation =
    utmContent === null || pageLocation === 'all' // Only use the title of the area if the pageLocation is a catch-all, or the utm_content param is not set.
      ? locationMap[pageLocation].title // Use the title of the area.
      : utmContent.split('').map(l => l.charAt(0).toUpperCase + l.split(1)); // Uppercase the first character of the utm_content param.

  const tags = data.prismicBootcamp.tags;
  const robots = getCustomRobotsFromTags(tags, 'noindex');

  return (
    <>
      <Seo
        title={data.prismicBootcamp.data.title.text}
        customTitle={data.prismicBootcamp.data.meta_title}
        description={data.prismicBootcamp.data.meta_description.text || data.prismicBootcamp.data.course_intro.text}
        image={data.prismicBootcamp.data.social_image.url}
        slug={location.pathname}
        robots={robots}
      />

      <BootcampView
        data={data.prismicBootcamp.data}
        currentLocation={currentLocation}
        utmContent={utmContent}
        actualLocation={pageLocation}
        testimonials={data.prismicBootcamp.data.wyb_testimonials_set.document.data.testimonial_carousel_items}
        companyIcons={data.prismicBootcamp.data.company_icons}
        locationData={data.allPrismicMasterLocation.nodes[0].data.locations}
      />
    </>
  )
}

export default withPreview(BootcampViewTemplate)

export const PageQuery = graphql`
  query BootcampViewTemplateQuery (
    $uid: String!
    $masterLocations: [String!]!
  ) {
    prismicBootcamp (
      uid: {
        eq: $uid
      }
    ) {
      uid
      tags
      data {
        title {
          text
          html
          raw
        }
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
        apply_cta_link {
          ...Link
        }
        curriculum_form_guid
        logo_group {
          ...LogoGroup
        }
        first_blog_article {
          url
          document {
            ...BlogArticle
          }
        }
        second_blog_article {
          url
          document {
            ...BlogArticle
          }
        }

        the_course_video {
          ...Embed
          embed_url
        }
        the_course_heading {
          text
        }
        the_course_intro_body {
          html
        }

        tech_stack_technologies {
          technologies_icon {
            ...Image
          }
          technologies_heading {
            text
          }
        }
        tech_stack_top_image {
          ...Image
        }
        tech_stack_btm_image {
          ...Image
        }


        course_intro {
          html
          text
        }
        learn_in_person_desc {
          html
        }
        address {
          html
        }
        learn_remotely_desc {
          html
        }
        course_illustration {
          ...Image
        }
        course_dates_title {
          text
        }
        course_dates {
          course_dates_location {
            text
          }
          course_dates_extra_label {
            text
          }
          course_dates_date(formatString: "Do MMMM YYYY")
          course_dates_display_apply_now_button
          course_dates_date_closing {
            text
          }
          course_dates_duration {
            text
          }
          course_dates_timetable {
            text
          }
          course_dates_price {
            text
          }
          course_dates_scholarship_label {
            text
          }
        }
        wyb_testimonials_set {
          isBroken
          document {
            ...TestimonialCarousel
          }
        }
        course_faqs_title {
          text
        }
        course_faqs_tabs {
          course_faqs_header {
            text
          }
          course_faqs_body {
            html
          }
        }
        course_faqs_cta_label {
          text
        }
        course_faqs_cta_link {
          ...Link
        }
        tech_stack_intro {
          html
        }
        google_maps_iframe {
          text
        }
        average_starting_salary {
          text
        }
        hero_image {
          ...Image
        }
        next_course_date
        hero_image_background
        hero_show_logo
        first_section_heading {
          text
        }
        first_section_body {
          text
        }
        second_section_heading {
          text
        }
        second_section_body {
          text
        }
        third_section_heading {
          text
        }
        third_section_body {
          text
        }
        top_video {
          ...Embed
          embed_url
        }
        employed_percentage {
          text
        }
        course_items {
          week_title {
            text
          }
          week_type {
            text
          }
          week_description {
            text
          }
        }
        info_grid {
          icon {
            ...Image
          }
          info_grid_header {
            raw
            html
            text
          }
          info_grid_content {
            html
          }
        }
        tech_stack_technologies {
          technologies_icon {
            ...Image
          }
          technologies_heading {
            text
          }
        }
        company_icons {
          company_icon {
            ...Image
          }
        }
        ap_process_list {
          ap_process_list_header {
            text
          }
          ap_process_list_body {
            html
          }
        }
        ways_to_pay_items {
          ways_to_pay_item_title {
            text
          }
          ways_to_pay_item_price {
            text
          }
          ways_to_pay_item_desc {
            text
          }
        }
        phone_number {
          text
        }
      }
    }

    allPrismicMasterLocation(
      filter: {
        uid: {
          in: $masterLocations
        }
      }
    ) {
      nodes {
        uid
        data {
          locations {
            location_image {
              ...Image
            }
            location_name {
              text
            }
            location_description {
              text
            }
          }
        }
      }
    }

    # masterLocations: allPrismicMasterLocation {
    #   nodes {
    #     data {
    #       locations {
    #         location_image {
    #           ...Image
    #         }
    #         location_name {
    #           text
    #         }
    #         location_description {
    #           text
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
