import React, { useState } from 'react'

// Generic
import Container from '../../shared/Container/Container'

// Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'

// Swiper components
import SwiperNav from '../../shared/Swiper/SwiperNav'

SwiperCore.use([EffectFade])

const BootcampCourseCarousel = (props) => {
  const [swiper, setSwiper] = useState(null)
  const { items } = props;

  return (
    <section className='mb-16 md:mb-32 mt-10'>
      <Container width='default' className='pb-16 sm:pb-32' hasPadding={false}>
        <div className='relative'>
          <Swiper
            onSwiper={setSwiper}
            spaceBetween={20}
            slidesPerView={4}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              }
            }}
          >
            {items.map((item, itemIndex) => {
              return (
                <SwiperSlide key={itemIndex} className="pb-4">
                  <div className='bg-white border-whiteSmoke border px-5 py-8 shadow-md'>
                    <h1 className='text-4xl font-bold'>{item.week_title.text}</h1>
                    <div className='label bg-ncYellow inline-block px-4 rounded-sm uppercase font-medium my-4 text-base'>{item.week_type.text}</div>

                    <p>{item.week_description.text}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <SwiperNav
            swiper={swiper}
            wrapperClassName='hidden sm:block absolute mt-6 left-1/2 transform -translate-x-1/2 z-10 text-center'
            containerClassName={'pt-20'}
            ctaClassName='bg-white text-primary hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
          />
        </div>
        <div className="w-full grid justify-center">
          <SwiperNav
            swiper={swiper}
            wrapperClassName='sm:hidden'
            containerClassName='-ml-4'
            ctaClassName='bg-primary text-white hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
          />
        </div>
      </Container>
    </section>
  )
}

export default BootcampCourseCarousel

// export const query = graphql`
//   fragment WhyCarousel on PrismicPage {
//     data {
//       body {
//         ... on PrismicPageBodyWhyCarousel {
//           id
//           slice_type
//           primary {
//             display
//             why_carousel_title {
//               raw
//               text
//             }
//           }
//           items {
//             why_carousel_illustration {
//               ...Image
//             }
//             why_carousel_header {
//               text
//               raw
//             }
//             why_carousel_body_right {
//               html
//             }
//             why_carousel_body_left {
//               html
//             }
//           }
//         }
//       }
//     }
//   }
// `
