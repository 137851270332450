import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "gatsby";

// Generic
import Container from "../../shared/Container/Container";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";
import Image from "../../shared/Image/Image";
import Heading from "../../shared/Heading/Heading";
import LogoGroup from "../../shared/LogoGroup/LogoGroup";

// Icons
import LogoSymbolRed from "../../../assets/img/logo-symbol-red.svg";
import Calender from "../../../assets/img/cal.svg";
import ChevronRight from "../../../assets/img/chevron-right.svg";

import ApplicantsSvg from "../../../assets/img/bootcamp/applicants.svg";
import ContactHours from "../../../assets/img/bootcamp/contact-hours.svg";
import StartingSalary from "../../../assets/img/bootcamp/starting-salary.svg";
import GraduatesTrained from "../../../assets/img/bootcamp/graduates-trained.svg";

// Utils
import getBreakpointWithoutUnit from "../../../utils/getBreakpointWithoutUnit";

const BootcampHero = (props) => {
  const {
    currentLocation,
    utmContent,
    fullyFunded,
    averageStartingSalary,
    employedPercentage,
  } = props;

    return (
      <>
        <section
          className={classNames('c-hero', {
            'c-hero--has-blocks': props.data.hasBlocks,
          })}
        >
          <div className='c-hero__inner'>
            <Container className='flex w-full'>
              <div className='c-hero__content'>
                <h1
                  className='h1'
                >
                  {props.data.primary.hero_title.text} in {currentLocation}
                </h1>
                <div className='c-hero__body space-y-4'>
                  {props.data?.primary?.hero_intro?.html && (
                    <HtmlContent
                      html={props.data.primary.hero_intro.html}
                      className='text-xl sm:text-2xl mt-6 c-prose'
                    />
                  )}
                  {props.data?.primary?.hero_body?.html && (
                    <HtmlContent
                      html={props.data.primary.hero_body.html}
                      className='hidden sm:block text-lg mt-6 c-prose'
                    />
                  )}
                </div>

                {(props.data.items && props.data.items.length > 0) && (
                  <div className='mt-8 sm:mt-12 flex flex-wrap'>
                    {props.data.items.map((item, itemIndex) => {
                      return (
                        <Button
                          {...item.hero_cta_link}
                          className='mb-4 mr-4'
                          key={itemIndex}
                          color={item.cta_style}
                          icon
                        >
                          {item.hero_cta_label.text}
                        </Button>
                      )
                    })}
                  </div>
                )}


                {props.additionalContent}
              </div>
            </Container>
            {props.data.primary?.hero_image?.url && (
              <div
                className={classNames('c-hero__img', {
                  'text-primary': props.data.primary.hero_image_background === 'Primary',
                  'text-secondary-dark': props.data.primary.hero_image_background === 'Secondary'
                })}
              >
                <Image
                  className='relative z-10'
                  fallbackAlt={props.data.primary.hero_title.text}
                  lazyload={false}
                  sizes={[
                    `(max-width: ${getBreakpointWithoutUnit('sm') - 1}px) 85vw`,
                    `(max-width: ${getBreakpointWithoutUnit('xl') - 1}px) 50vw`,
                    '686px'
                  ]}
                  {...props.data.primary.hero_image}
                />
              </div>
            )}
            {props.data?.primary?.hero_show_logo && <LogoSymbolRed className='hidden sm:block c-hero__logo' />}
          </div>
          {props.data?.primary?.hero_illustration?.url && (
            <div
              className='hidden sm:block c-hero__illustration'
            >
              <Container className='sm:px-0 xl:px-4'>
                <div className='c-hero__illustration-inner'>
                  <Image
                    fallbackAlt={props.data.primary.hero_title.text}
                    lazyload={false}
                    sizes={[
                      '(max-width: 1399px) 45vw',
                      '630px'
                    ]}
                    {...props.data.primary.hero_illustration}
                  />
                </div>
              </Container>
            </div>
          )}
        </section>
      </>
    );


return null;
  return (
    <>
      <section
        className={classNames("c-hero", {
          "c-hero--has-blocks": props.data.hasBlocks,
        })}
      >
        <div className="c-hero__inner z-aboveAll">
          <Container className="flex w-full">
            <div className="md:c-hero__content">
              <span className="block w-3/4">
                {utmContent === null ? (
                  <h1>
                    {props.data.primary.hero_title.text} in {currentLocation}
                  </h1>
                ) : (
                  <h1>
                    {props.data.primary.hero_title.text} near {utmContent}
                  </h1>
                )}
              </span>
              <div className="c-hero__body space-y-4">
                {props.data?.primary?.hero_intro?.html && (
                  <HtmlContent
                    html={props.data.primary.hero_intro.html}
                    className="text-xl sm:text-2xl mt-6 c-prose"
                  />
                )}
                {props.data?.primary?.hero_body?.html && (
                  <HtmlContent
                    html={props.data.primary.hero_body.html}
                    className="hidden sm:block text-lg mt-6 c-prose"
                  />
                )}
              </div>

              <div
                className="
                flex flex-row
                mt-6
              "
              >
                <div className="bg-primary flex items-center align-middle">
                  <Calender className="w-3/4  mx-auto" />
                </div>

                <div className="p-4 px-6 bg-white">
                  <span className="font-bold">Apply for our next course</span>
                  <h2 className="font-bold text-primary">Time</h2>
                </div>

                <div className="bg-primary text-white flex items-center align-middle p-4">
                  <ChevronRight className="w-6 fill-current" />
                </div>
              </div>

              <div className="bg-white shadow-sm w-full c-hero__content mt-12 p-2">
                <ul className="grid grid-cols-4 text-center items-center">
                <li className="flex flex-col">
                    <ApplicantsSvg className="mx-auto w-12" />
                    <p className="justify-self-end text-xs py-2">
                      <strong className="block -mb-1 text-base">30,000</strong>
                      Applicants for our courses
                    </p>
                  </li>
                  <li className="flex flex-col">
                    <ContactHours className="mx-auto w-12" />
                    <p className="justify-self-end text-xs py-2">
                      <strong className="block -mb-1 text-base">480+</strong>
                      Contact hours
                    </p>
                  </li>
                  <li className="flex flex-col">
                    <StartingSalary className="mx-auto w-12" />
                    <p className="justify-self-end text-xs py-2">
                      <strong className="block -mb-1 text-base">£26.5K</strong>
                      Average starting salary
                    </p>
                  </li>
                  <li className="flex flex-col">
                    <GraduatesTrained className="mx-auto w-12" />
                    <p className="justify-self-end text-xs py-2">
                      <strong className="block -mb-1 text-base">3,000</strong>
                      Graduates trained
                    </p>
                  </li>
                </ul>
              </div>
              <LogoGroup
                className="mt-auto pt-8 c-hero__logos"
                logosDocument={props.data.primary?.logo_group?.document}
                fallbackAlt={props.data.primary.hero_title.text}
              />
            </div>
          </Container>
          {props.data.primary?.hero_image?.url && (
            <div
              className={classNames("c-hero__img", {
                "text-primary":
                  props.data.primary.hero_image_background === "Primary",
                "text-secondary-dark":
                  props.data.primary.hero_image_background === "Secondary",
              })}
            >
              <Image
                className="relative z-10"
                fallbackAlt={props.data.primary.hero_title.text}
                lazyload={false}
                sizes={[
                  `(max-width: ${getBreakpointWithoutUnit("sm") - 1}px) 85vw`,
                  `(max-width: ${getBreakpointWithoutUnit("xl") - 1}px) 50vw`,
                  "686px",
                ]}
                {...props.data.primary.hero_image}
              />
            </div>
          )}
          {props.data?.primary?.hero_show_logo && (
            <LogoSymbolRed className="hidden sm:block c-hero__logo" />
          )}
        </div>
        {props.data?.primary?.hero_illustration?.url && (
          <div className="hidden md:block c-hero__illustration">
            <Container className="md:px-0 xl:px-4">
              <div className="c-hero__illustration-inner">
                <Image
                  fallbackAlt={props.data.primary.hero_title.text}
                  lazyload={false}
                  sizes={["(max-width: 1399px) 45vw", "630px"]}
                  {...props.data.primary.hero_illustration}
                />
              </div>
            </Container>
          </div>
        )}
        <div className="hidden md:block bg-secondary-light absolute right-0 top-1/4 w-1/2 h-full transform -rotate-6"></div>
      </section>
    </>
  );
};

BootcampHero.propTypes = {
  data: PropTypes.object.isRequired,
  additionalContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
};

export default BootcampHero;

export const query = graphql`
  fragment Hero on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyHero {
          id
          slice_type
          primary {
            hero_title {
              html
              text
              raw
            }
            hero_intro {
              html
            }
            hero_body {
              html
            }
            hero_image {
              ...Image
            }
            hero_image_background
            hero_illustration {
              ...Image
            }
            hero_show_logo
            logo_group {
              ...LogoGroup
            }
          }
          items {
            hero_cta_label {
              text
            }
            hero_cta_link {
              ...Link
            }
            cta_style
          }
        }
      }
    }
  }
`;
