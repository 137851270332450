import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

// Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade } from "swiper";

// Swiper components
import SwiperNav from "../../shared/Swiper/SwiperNav";

SwiperCore.use([EffectFade]);

// Generic
import Container from "../../shared/Container/Container";
import Edge from "../../shared/Edge/Edge";
import TwoColGrid from "../../shared/TwoColGrid/TwoColGrid";
import TwoColGridItem from "../../shared/TwoColGrid/TwoColGridItem";
import Button from "../../shared/Button/Button";
import Image from "../../shared/Image/Image";
import FiftyFiftyTriangle from "../FiftyFifty/FiftyFiftyTriangle";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";

const InfoGrid = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [whereToStudySwiper, setWhereToStudySwiper] = useState(null);
  const {
    items,
    edgeOuterClassName,
    edgeInnerClassName,
    techStack,
    technologies,
    testimonials,
    companyIcons,
    courseItems,
    processList,
    locationData
  } = props;
  return (
    <section>
      <div className="bg-primary">
        <Edge
          location="top"
          direction="left"
          outerClassName="text-white"
          innerClassName="text-primary"
        />
        <Container width="thinner" className="mt-1/4 py-10 sm:py-20 text-white">
          <div className="hidden md:block">
            <h1 className="text-4xl md:text-6xl font-medium mb-12">Why Northcoders?</h1>
            <div className="sm:grid grid-cols-2 gap-x-6 gap-y-10 space-y-10 sm:space-y-0">
              {items.map((item, itemIndex) => {
                return (
                  <TwoColGridItem
                    key={itemIndex}
                    icon={item.icon}
                    header={item.info_grid_header}
                    body={item.info_grid_content.html}
                  />
                );
              })}
            </div>
          </div>

            <div className="relative -mb-24 mt-24 z-30">
              <FiftyFiftyTriangle
                  location={'Top Left'}
                />
            </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-6">
            <div>
              <div className="mt-24 z-20 relative block bg-white text-black p-12">
                <h2 className="font-normal mb-4">Tech Stacks</h2>
                <p>
                  Stuff about how agnostic we are. Is that the right word? Duis
                  aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <ul className="mt-4 flex flex-row flex-wrap gap-4">
                  {technologies.map((tech, index) => (
                    <li className="bg-gray-lighter p-2 px-4 rounded-md font-bold">
                      {tech.technologies_heading.text}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative mt-12 z-0">
                <FiftyFiftyTriangle
                    location={'Bottom Right'}
                  />
              </div>
            </div>

            <div className="flex flex-col mt-24 gap-6">
              <Image className="w-full md:w-80" {...techStack.topImage} />
              <Image className="w-full md:w-125" {...techStack.btmImage} />
            </div>
          </div>

          <div className="mt-24">
            <h2 className="font-normal">Where would you like to study?</h2>
            <ul className="flex flex-row gap-x-6 my-6 font-medium">
              <li className="bg-black text-white p-4 cursor-pointer">
                United Kingdom
              </li>
              <li className="bg-white text-black p-4 cursor-pointer">Europe</li>
            </ul>

            <Swiper
              onSwiper={setWhereToStudySwiper}
              spaceBetween={20}
              slidesPerView={4}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
                {
                  locationData.map(loc => (
                    <SwiperSlide className="bg-white text-black shadow-sm">
                    <Image
                      className="w-full h-24 mx-auto"
                      {...loc.location_image}
                    />
                    <p className="text-primary font-medium p-4 text-xl">
                      {loc.location_name.text}
                    </p>
                    </SwiperSlide>
                  ))
                }

            </Swiper>

            <div className="grid w-full justify-center">
              <SwiperNav
                swiper={whereToStudySwiper}
                wrapperClassName="mt-6"
                containerClassName="-ml-4"
                ctaClassName="bg-white text-primary hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary"
              />
            </div>
          </div>
        </Container>
      </div>
      <Edge
        location="bottom"
        direction="left"
        outerClassName={
          edgeOuterClassName ? edgeOuterClassName : "text-offWhite"
        }
        innerClassName={
          edgeInnerClassName ? edgeInnerClassName : "text-primary"
        }
      />

      <div className="bg-offWhite">
        <Container width="default" className="py-10 sm:py-20 text-black ">
          <h2 className="font-normal">What you'll become</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 justify-between w-full my-6">
            <p className="w-3/4 text-xl">
              We’ve had people from all walks of life graduate from our software
              bootcamp...
            </p>
            <div className="flex flex-row gap-x-6 justify-end items-center">
              <span className="text-2xl md:text-5xl text-primary font-bold">£26.5K</span>
              <p className="text-sm text-center font-bold w-1/4">
                Average salary of a software developer in the UK
              </p>
            </div>
          </div>

          <Swiper
            onSwiper={setSwiper}
            spaceBetween={20}
            slidesPerView={4}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {testimonials.map((t, index) => {
              const roles = t.testimonial_carousel_role.text;
              let [before, after] = [];
              if (roles) {
                [before, after] = roles.split("to");
              }

              return (
                <SwiperSlide key={index} className="bg-white shadow-sm">
                  <Image
                    fallbackAlt={t.testimonial_carousel_name.text}
                    {...t.testimonial_carousel_avatar}
                  />
                  <div className="p-4 flex flex-col gap-2">
                    <h3 className="text-2xl">
                      {before} <span className="text-primary">to</span> {after}
                    </h3>
                    <div className="bg-ncYellow px-4 py-1 rounded-md self-start text-sm font-bold">
                      {t.testimonial_carousel_name.text}
                    </div>
                    <p>{t.testimonial_carousel_body.text}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="w-full grid justify-center">
            <SwiperNav
              swiper={swiper}
              wrapperClassName="sm:hidden mb-6"
              containerClassName="-ml-4"
              ctaClassName="bg-primary text-white hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary"
            />
          </div>

        </Container>
      </div>
      <Edge
        location="bottom"
        direction="left"
        outerClassName={edgeOuterClassName ? edgeOuterClassName : "text-white"}
        innerClassName={
          edgeInnerClassName ? edgeInnerClassName : "text-offWhite"
        }
      />

      <Container width="default" className="py-10 sm:py-20 text-black">
        <div className="text-center mx-auto">
          <p className="text-xl mb-6">
            Students have access to our careers team who have helped graduates
            <br />
            <strong>get jobs at companies like...</strong>
          </p>

          <div className="w-full grid justify-center">
            <div className="flex flex-row gap-12 flex-wrap w-full">
              {
                companyIcons.map((icon, index) => (
                  <Image
                    key={index}
                    className="w-24"
                    {...icon.company_icon}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </Container>

      <Edge
        location="bottom"
        direction="left"
        outerClassName={
          edgeOuterClassName ? edgeOuterClassName : "text-primary"
        }
        innerClassName={edgeInnerClassName ? edgeInnerClassName : "text-white"}
      />
      <section className="bg-primary">
        <Container width="thinnest" className="py-10 sm:py-20 text-white">
          <h1 className="text-4xl md:text-6xl font-medium mb-12">Application Process</h1>

          <ul className="grid grid-cols-2 gap-y-12">
            {
              processList.map((process, index) => {
                const currentStep = index + 1;
                return (
                  <li>
              <div className="bg-white rounded-md text-primary inline-block px-6 py-2 text-2xl font-bold">
                {currentStep}
              </div>
              <div className="mt-2">
                <strong className="text-xl">{process.ap_process_list_header.text}</strong>
                <p>{process.ap_process_list_header.text}</p>
                {/* <HtmlContent
                  html={process.ap_process_list_header.text}
                  className='c-prose font-normal text-base '
                /> */}
              </div>
            </li>
                );
              })
            }
          </ul>

          <div className="w-full grid">
            <Button
              color="secondary"
              className="bg-black text-white mt-12 place-self-end"
            >
              Register Now
            </Button>
          </div>
        </Container>
      </section>
      <Edge
        location="bottom"
        direction="left"
        outerClassName={edgeOuterClassName ? edgeOuterClassName : "text-white"}
        innerClassName={
          edgeInnerClassName ? edgeInnerClassName : "text-primary"
        }
      />
    </section>
  );
};

export default InfoGrid;

export const query = graphql`
  fragment InfoGrid on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyInfoGrid {
          id
          slice_type
          primary {
            display
            info_grid_image {
              ...Image
            }
            info_grid_intro {
              html
            }
            info_grid_title {
              text
              raw
            }
            info_grid_video_embed {
              ...Embed
            }
            info_grid_media_overlap_background
            info_grid_media_overlap_height
            info_grid_hubspot_form_guid
            info_grid_has_edge
            info_grid_remove_bottom_margin
          }
          items {
            info_grid_icon {
              ...Image
            }
            info_grid_header {
              text
              raw
            }
            info_grid_body {
              html
            }
          }
        }
      }
    }
  }
`;
