import React from 'react'
import Faqs from '../../../elements/Faqs/Faqs'
import Button from '../../../shared/Button/Button'
import Container from '../../../shared/Container/Container'
import HtmlContent from '../../../shared/HtmlContent/HtmlContent'

export default function FAQs({
    faqsTitleText,
    faqsTabs,
    ctaLink,
    labelText
}) {
  return (
    <div>
          <Container className={'mb-10'}>
              <h2 className='mb-10'>{faqsTitleText}</h2>
              <Faqs
                  canAnimate
                  className='mb-12'
                  items={faqsTabs.map((faq) => {
                      return {
                          header: faq.course_faqs_header.text,
                          body: (
                              <HtmlContent
                                  html={faq.course_faqs_body.html}
                                  className='text-lg mb-6 c-prose mt-4'
                              />
                          )
                      }
                  })}
              />
              <Button
                  {...ctaLink}
                  color='inline'
                  icon
              >
                  {labelText}
              </Button>
          </Container>
    </div>
  )
}
