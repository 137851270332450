import React from "react";
import PropTypes from "prop-types";

// Local components
import PayOptionsInfoGrid from "../../elements/InfoGrid/PayOptionsInfoGrid";
import BootcampInfoGrid from "../../elements/InfoGrid/BootcampInfoGrid";
import BootcampHero from "../../elements/Hero/BootcampHero";
import CourseSection from "./sections/TheCourse";

import TheTechStack from "./sections/TheTechStack";
import TheLearningEnvironment from "./sections/TheLearningEnvironment";
import WhatYoullBecome from "./sections/WhatYoullBecome";
import OurCourseDates from "./sections/OurCourseDates";
import ApplicationProcess from "./sections/ApplicationProcess";
import FAQs from "./sections/FAQs";
import { formatBlogArticles } from "../../../utils/blogArticleDecorator";
import LogoSymbolRed from "../../../assets/img/logo-symbol-red.svg";
import Calender from "../../../assets/img/cal.svg";
import ChevronRight from "../../../assets/img/chevron-right.svg";

import ApplicantsSvg from "../../../assets/img/bootcamp/applicants.svg";
import ContactHours from "../../../assets/img/bootcamp/contact-hours.svg";
import StartingSalary from "../../../assets/img/bootcamp/starting-salary.svg";
import GraduatesTrained from "../../../assets/img/bootcamp/graduates-trained.svg";
import FiftyFiftyTriangle from "../../elements/FiftyFifty/FiftyFiftyTriangle";
import BootcampCourseCarousel from "../../elements/BootcampCourseCarousel/BootcampCourseCarousel";
import { Container } from "@material-ui/core";
import LogoGroup from "../../shared/LogoGroup/LogoGroup";

const BootcampView = ({
  data,
  currentLocation,
  utmContent,
  actualLocation,
  testimonials,
  companyIcons,
  locationData
}) => {
  // const readableAreaLocation = actualLocation.split(" ").map((letter) => {
  //   return letter.charAt(0).toUpperCase() + letter.slice(1).toLowerCase();
  // });
  return (
    <>
      <BootcampHero
        isBootcampPage
        data={{
          primary: {
            hero_title: data.title,
            hero_intro: data.course_intro,
            hero_image: data.hero_image,
            hero_illustration: data.course_illustration,
            logo_group: data.logo_group,
            ...data,
          },
        }}
        currentLocation={currentLocation}
        utmContent={utmContent}
        fullyFunded={data.fully_funded_in_header}
        averageStartingSalary={data.average_starting_salary}
        employedPercentage={data.employed_percentage}
        additionalContent={
          <>
            <div
              className="
                flex flex-row
                mt-6
                w-full
              "
            >
              <div className="bg-primary flex items-center align-middle px-3">
                <Calender className="w-3/4 mx-auto" />
              </div>

              <div className="p-4 px-6 bg-white w-full md:w-auto">
                <span className="font-bold">Apply for our next course</span>
                <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl text-primary">{data.next_course_date}</h2>
              </div>

              <div className="bg-primary text-white flex items-center align-middle p-4">
                <ChevronRight className="w-6 fill-current" />
              </div>
            </div>

          <div className="relative mt-12 px-3 hidden md:block">
          <FiftyFiftyTriangle
                location={'Top Left'}
              />
            <div className="bg-white shadow-lg w-full c-hero__content p-2">
              <ul className="grid grid-cols-2 lg:grid-cols-4 text-center items-center">
                <li className="flex flex-col">
                  <ApplicantsSvg className="mx-auto w-12" />
                  <p className="justify-self-end text-xs py-2">
                    <strong className="block -mb-1 text-base">30,000</strong>
                    Applicants for our courses
                  </p>
                </li>
                <li className="flex flex-col">
                  <ContactHours className="mx-auto w-12" />
                  <p className="justify-self-end text-xs py-2">
                    <strong className="block -mb-1 text-base">480+</strong>
                    Contact hours
                  </p>
                </li>
                <li className="flex flex-col">
                  <StartingSalary className="mx-auto w-12" />
                  <p className="justify-self-end text-xs py-2">
                    <strong className="block -mb-1 text-base">£26.5K</strong>
                    Average starting salary
                  </p>
                </li>
                <li className="flex flex-col">
                  <GraduatesTrained className="mx-auto w-12" />
                  <p className="justify-self-end text-xs py-2">
                    <strong className="block -mb-1 text-base">3,000</strong>
                    Graduates trained
                  </p>
                </li>
              </ul>
            </div>
            <div className="mt-10">
            <FiftyFiftyTriangle
                location={'Bottom Right'}
              />
              </div>
            </div>
          </>
        }
      />
      <div className="relative z-aboveAll2 w-full">
              <LogoGroup
                className='c-hero__logos absolute -top-40 right-0'
                logosDocument={data.logo_group?.document}
                fallbackAlt={data.title.text}
              />
          </div>
      <div className="relative">
        <CourseSection items={data.course_items} embed={data.the_course_video} courseHeading={data.the_course_heading.text} courseBody={data.the_course_intro_body.html} />
              <div className="-mb-1/3">
                <Container width="default" className="">
                  <h1 className="text-4xl md:text-6xl inline-block  font-sans">What you'll learn</h1>
                  </Container>
                <BootcampCourseCarousel items={data.course_items} />
              </div>
        <div >
          <BootcampInfoGrid items={data.info_grid} techStack={{
            topImage: data.tech_stack_top_image,
            btmImage: data.tech_stack_btm_image,
          }} technologies={data.tech_stack_technologies} testimonials={testimonials} companyIcons={companyIcons} courseItems={data.course_items} processList={data.ap_process_list} locationData={locationData} />
        </div>
      </div>
      {/* <TheTechStack
        techStackIntro={data.tech_stack_intro.html}
        technologies={data.tech_stack_technologies}
      />
      <TheLearningEnvironment
        blogArticles={blogArticles}
        currentLocation={currentLocation}
        readableAreaLocation={readableAreaLocation}
        topVideo={data.top_video}
        topVideoUrl={data.top_video.embed_url}
        learnInPersonDesc={data.learn_in_person_desc.html}
        learnRemotelyDesc={data.learn_remotely_desc.html}
        address={data.address.html}
        map={data.google_maps_iframe.text}
        averageStartingSalary={data.average_starting_salary.text}
      />
      <WhatYoullBecome
        carouselSet={
          data.wyb_testimonials_set?.document?.data.testimonial_carousel_items
        }
        companyIcons={data.company_icons}
      />
      <OurCourseDates courseDates={data.course_dates} />
      <ApplicationProcess
        data={data}
        illustration={data.illustration}
        firstSectionHeadingText={data.first_section_heading.text}
        firstSectionBodyText={data.first_section_body.text}
        secondSectionHeaderText={data.second_section_heading.text}
        sectionSectionBodyText={data.second_section_body.text}
        thirdSectionHeaderText={data.third_section_heading.text}
        thirdSectionBodyText={data.third_section_body.text}
      />
      <PayOptionsInfoGrid
        options={data.ways_to_pay_items}
        phoneNumber={data.phone_number}
      /> */}
      <FAQs
        data={data}
        faqsTitleText={data.course_faqs_title.text}
        faqsTabs={data.course_faqs_tabs}
        ctaLink={data.course_faqs_cta_link}
        labelText={data.course_faqs_cta_label.text}
      />
    </>
  );
};

BootcampView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BootcampView;
