import React from "react";
import BootcampCourseCarousel from "../../../elements/BootcampCourseCarousel/BootcampCourseCarousel";
import Container from "../../../shared/Container/Container";
import Embed from "../../../shared/Embed/Embed";
import HtmlContent from "../../../shared/HtmlContent/HtmlContent";
import Button from "../../../shared/Button/Button";

export default function CourseSection({ items, embed, courseHeading, courseBody}) {
  return (
    <Container className="mb-12">
      <h1 className="text-4xl md:text-6xl inline-block font-sans mb-10">The Course</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12 items-start">
        {embed.html ? <Embed embed={embed} /> : null}
        <div>
          <h2 className="text-2xl md:text-4xl pb-4">{courseHeading}</h2>
          <HtmlContent
            html={courseBody}
            className={'c-prose mb-4'}
          />
          <Button
            color="secondary"
            className='cursor-pointer'
          >
            Download Cirriculum
            </Button>
        </div>
      </div>
    </Container>
  );
}
